import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@hooks";
import { loginRequest } from "@Store/App/actions";
import { ILoginData } from "@Store/App/types";
import i18n from "@i18n";
import { object, string } from "yup";
import { Field, Form, Formik } from "formik";
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import "./index.scss";
import { useStyles } from "@Pages/Login/theme";
import UnAuthenticatedHeader from "@Components/UnAuthenticatedHeader";

const schema = object().shape({
  username: string().required(i18n.t("errors.validation.requiredField")),
  password: string().required(i18n.t("errors.validation.requiredField")),
});

const initialValues: any = {
  username: "",
  password: "",
};

function LoginPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
  }, []);

  const onSubmit = (data: ILoginData) => {
    localStorage.setItem("emailAddress", data.username.toLowerCase());
    dispatch(loginRequest(data));
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <UnAuthenticatedHeader />
      <Container
        maxWidth="sm"
        sx={{
          width: "400px",
        }}
      >
        <Box textAlign="center" mt={5}>
          <img
            src={`${process.env.PUBLIC_URL}/Assets/blackpin_logo_small.svg`}
            alt="Logo"
            width={200}
          />
          <p
            style={{
              fontSize: "25px",
              textAlign: "center",
              marginBlockStart: "20px",
              marginBlockEnd: "30px",
            }}
          >
            {t("general.logIn")}
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <span className={classes.inputLabel}>
                  {t("general.username")}
                </span>
                <Field
                  name="username"
                  as={TextField}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  className={classes.input}
                  error={touched.username && Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                />
                <span className={classes.inputLabel}>
                  {t("general.password")}
                </span>
                <Field
                  name="password"
                  as={TextField}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  className={classes.input}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          className={classes.iconButton}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.button}
                  >
                    {t("general.logIn")}
                  </Button>
                </Box>
                {sessionStorage.getItem("userRoleFail") && (
                  <p style={{ color: "red", padding: "10px" }}>
                    {t("general.loginFailRole")}
                  </p>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
}

export default LoginPage;
