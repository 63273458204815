import React, {FunctionComponent, useEffect, useState} from "react";
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import FileUpload from "@Components/FileUpload/file-upload";
import {useAppSelector} from "@hooks";
import {RootState} from "@Store";
import {selectCurrentOrganization} from "@Store/Organizations/selectors";
import {bulkImportTeamRequest} from "@Store/Team/actions";

export interface ModalBulkUploadTeamsProps {
  visible: boolean;
  onClose: () => void;
}

const ModalBulkUploadTeams: FunctionComponent<ModalBulkUploadTeamsProps> = ({
                                                                              visible,
                                                                              onClose,
                                                                            }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(() => new FormData());
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {currentOrganization} = useAppSelector((state: RootState) => ({
    currentOrganization: selectCurrentOrganization(state),
  }));

  const updateFormData = (newFormData: FormData) => {
    setFormData(newFormData);
  };

  const handleSubmit = () => {
    const file = formData.get("file") as File;
    if (!file) {
      setError(t("bulkUpload.modal.fileInfo"));
      return;
    }

    if (file.name.endsWith(".xls") || file.name.endsWith(".xlsx")) {
      if (currentOrganization?.orgId) {
        dispatch(
          bulkImportTeamRequest({
            orgId: currentOrganization?.orgId,
            file: file,
          })
        );
      }

      onClose();
    } else {
      setError(t("bulkUpload.modal.errorProcessingFile"));
    }
  }

  useEffect(() => {
    if (!visible) {
      setFormData(new FormData());
      setIsFileUploaded(false);
      setError(null);
    }
  }, [visible]);

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>{t("bulkUploadTeam.modal.title")}</DialogTitle>
      <DialogContent>
        <FileUpload
          className="bulk-upload-file"
          id="bulk-upload-file"
          name="file"
          accept=".xls, .xlsx"
          formData={formData}
          updateParentFormData={updateFormData}
          labelText={t("bulkUploadTeam.modal.uploadButton")}
          onFileStateChange={setIsFileUploaded}
          fileInfo={t("bulkUploadTeam.modal.fileInfo")}
          buttonText={t("bulkUploadTeam.modal.uploadButton")}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("bulkUploadTeam.buttons.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!isFileUploaded}
        >
          {t("bulkUploadTeam.buttons.upload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalBulkUploadTeams;
