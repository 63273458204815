import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FileUpload from "@Components/FileUpload/file-upload";
import { useAppSelector } from "@hooks";
import { RootState } from "@Store";
import { selectCurrentOrganization } from "@Store/Organizations/selectors";
import { uploadCsvFileRequest } from "@Store/AppUser/actions";

export interface ModalBulkUploadTeamsProps {
  visible: boolean;
  onClose: () => void;
}

const ModalBulkUploadTeams: FunctionComponent<ModalBulkUploadTeamsProps> = ({
  visible,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(() => new FormData());
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { currentOrganization } = useAppSelector((state: RootState) => ({
    currentOrganization: selectCurrentOrganization(state),
  }));

  const updateFormData = (newFormData: FormData) => {
    setFormData(newFormData);
  };

  const handleSubmit = () => {
    const file = formData.get("file") as File;

    if (!file) {
      setError(t("bulkUpload.modal.fileInfo"));
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      let csvContent = reader.result as string;

      // Replace semicolons with commas
      csvContent = csvContent.replace(/;/g, ",");

      const lines = csvContent
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line);
      const plainTextContent = lines.join("\n");

      if (currentOrganization?.orgId) {
        dispatch(
          uploadCsvFileRequest({
            orgId: currentOrganization?.orgId,
            csvContent: plainTextContent,
          })
        );
      }
      onClose();
    };
    reader.onerror = () => {
      setError(t("bulkUpload.modal.errorReadingFile"));
    };

    reader.readAsText(file);
  };

  useEffect(() => {
    if (!visible) {
      setFormData(new FormData());
      setIsFileUploaded(false);
      setError(null);
    }
  }, [visible]);

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>{t("bulkUpload.modal.title")}</DialogTitle>
      <DialogContent>
        <FileUpload
          className="bulk-upload-file"
          id="bulk-upload-file"
          name="file"
          accept=".csv"
          formData={formData}
          updateParentFormData={updateFormData}
          labelText={t("bulkUpload.modal.uploadButton")}
          onFileStateChange={setIsFileUploaded}
          fileInfo={t("bulkUpload.modal.fileInfo")}
          buttonText={t("bulkUpload.modal.uploadButton")}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("bulkUpload.buttons.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!isFileUploaded}
        >
          {t("bulkUpload.buttons.upload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalBulkUploadTeams;
