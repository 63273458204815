import {AxiosResponse} from "axios";
import {ICreateTeamPayload, ICreateTeamResponse, IGetTeamsResponse,} from "./types";
import {getAxiosAdminOrganizationRequest, getAxiosBulkImportTeamRequest, getAxiosOrganizationRequest,} from "@API";

/**
 * Fetches the list of teams for a given organization.
 * @param {string} orgId The organization ID.
 * @returns {Promise<IGetTeamsResponse>} The response containing the teams.
 */
export const getAxiosTeamsRequest = async (
  orgId: string
): Promise<IGetTeamsResponse> => {
  const teamsRequest = getAxiosAdminOrganizationRequest();

  const response: AxiosResponse = await teamsRequest.get(`/${orgId}/teams`);

  return {data: response.data.teams};
};

/**
 * Creates a new team within an organization.
 * @param {string} orgId The organization ID.
 * @param {ICreateTeamPayload} payload The data to create the team.
 * @returns {Promise<ICreateTeamResponse>} The response containing the created team data.
 */
export const postAxiosCreateTeamRequest = async (
  orgId: string,
  payload: ICreateTeamPayload
): Promise<ICreateTeamResponse> => {
  const teamsRequest = getAxiosAdminOrganizationRequest();

  const response: AxiosResponse = await teamsRequest.post(
    `/${orgId}/teams`,
    payload
  );

  return {data: response.data};
};

export const patchAxiosUpdateTeamRequest = async (
  teamId: string,
  orgId: string,
  payload: ICreateTeamPayload
): Promise<ICreateTeamResponse> => {
  const teamsRequest = getAxiosAdminOrganizationRequest();

  const response: AxiosResponse = await teamsRequest.patch(
    `/${orgId}/teams/${teamId}`,
    payload
  );

  return {data: response.data};
};

export const getAxiosSingleTeamRequest = async (
  orgId: string,
  teamId: string
): Promise<any> => {
  const teamsRequest = getAxiosOrganizationRequest();

  const response: AxiosResponse = await teamsRequest.get(
    `/${orgId}/teams/${teamId}`
  );

  return {data: response.data};
};

export const activateTeamRequest = async (
  orgId: string,
  teamCode: string
): Promise<void> => {
  const teamsRequest = getAxiosAdminOrganizationRequest();
  await teamsRequest.put(`/${orgId}/teams/${teamCode}/activate`);
};

export const deactivateTeamRequest = async (
  orgId: string,
  teamCode: string
): Promise<void> => {
  const teamsRequest = getAxiosAdminOrganizationRequest();
  await teamsRequest.put(`/${orgId}/teams/${teamCode}/deactivate`);
};

export const postBulkImportTeamRequest = async (
  orgId: string,
  file: File
): Promise<void> => {

  const formData = new FormData();

  formData.append('file', file, file.name);

  const teamsRequest = getAxiosBulkImportTeamRequest();
  await teamsRequest.post(`/${orgId}/teams/bulk`, formData);
};
