import {
  deleteAxiosOrganizationUser,
  getAxiosOrganizationUsers,
  patchAxiosOrganizationUser,
  postAxiosOrganizationUser,
  putAxiosOrganizationUser,
} from "@API/Users/endpoints";
import {
  IAxiosOrganizationUser,
  IGetAxiosOrganizationUsers,
  IPatchAxiosOrganizationUsers,
} from "@API/Users/types";
import { updateOrganizationSuccess } from "@Store/Organizations/actions";
import {
  selectCurrentOrgId,
  selectCurrentOrganization,
} from "@Store/Organizations/selectors";
import {
  IOrganizationUser,
  OrganizationId,
  OrganizationUserId,
  PatchOrganizationUserBody,
} from "@Store/Organizations/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  createUserFail,
  createUserRequest,
  createUserSuccess,
  deleteUserFail,
  deleteUserRequest,
  deleteUserSuccess,
  resetUserModal,
  setUserAsAdmin,
  setUserModal,
  updateUserFail,
  updateUserRequest,
  updateUserSuccess,
  usersListFail,
  usersListRequest,
  usersListSuccess,
} from "./actions";
import { AxiosError, isAxiosError } from "axios";

function* onUsersListRequest({ payload }: PayloadAction<any>) {
  const { orgId, active } = payload;
  try {
    const response: IGetAxiosOrganizationUsers = yield call(
      getAxiosOrganizationUsers,
      orgId,
      active
    );
    if (response.error) throw response.error;

    yield put(usersListSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(usersListFail(e as Error));
  }
}

function* onCreateUserRequest({ payload }: PayloadAction<IOrganizationUser>) {
  try {
    const orgId: number = yield select(selectCurrentOrgId);
    const response: IAxiosOrganizationUser = yield call(
      // @ts-ignore
      postAxiosOrganizationUser,
      orgId,
      payload
    );
    if (response.error) throw response.error;

    yield put(resetUserModal());
    yield put(createUserSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(createUserFail(e as Error));
  }
}

function* onUpdateUserRequest({
  payload,
}: PayloadAction<
  {
    orgId: OrganizationId;
    userId: OrganizationUserId;
  } & PatchOrganizationUserBody
>) {
  try {
    const { orgId, userId, ...body } = payload;

    const response: IPatchAxiosOrganizationUsers = yield call(
      patchAxiosOrganizationUser,
      orgId,
      userId,
      body
    );

    if (response.error) throw response.error;

    yield put(resetUserModal());
    yield put(updateUserSuccess(response.data));
    yield put(usersListRequest({ orgId }));
  } catch (error) {
    const errorMessage =
      isAxiosError(error) && error.response
        ? error.response.data.message
        : (error as AxiosError).message;

    yield put(updateUserFail(error as Error));
  }
}

function* onSetUserAsAdmin({ payload }: PayloadAction<IOrganizationUser>) {
  try {
    const orgId: number = yield select(selectCurrentOrgId);
    const response: IAxiosOrganizationUser = yield call(
      // @ts-ignore
      putAxiosOrganizationUser,
      orgId,
      payload
    );
    if (response.error) throw response.error;

    yield put(resetUserModal());
    yield put(updateUserSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(updateUserFail(e as Error));
  }
}

function* onDeleteUserRequest({ payload }: PayloadAction<IOrganizationUser>) {
  try {
    const orgId: number = yield select(selectCurrentOrgId);
    // @ts-ignore
    const currentOrg: any = yield select(selectCurrentOrganization);
    const response: IAxiosOrganizationUser = yield call(
      // @ts-ignore
      deleteAxiosOrganizationUser,
      orgId,
      payload
    );
    if (response.error) throw response.error;
    // @ts-ignore
    const id = payload.id || payload.user.id;

    yield put(resetUserModal());
    yield put(deleteUserSuccess(id));
    yield put(
      updateOrganizationSuccess({
        ...currentOrg,
        administrators: currentOrg.administrators.filter(
          (item: any) => item !== id
        ),
      })
    );
  } catch (e) {
    console.error(e);
    yield put(deleteUserFail(e as Error));
  }
}

export default function* () {
  yield takeLatest(usersListRequest, onUsersListRequest);
  yield takeLatest(createUserRequest, onCreateUserRequest);
  yield takeEvery(updateUserRequest, onUpdateUserRequest);
  yield takeLatest(deleteUserRequest, onDeleteUserRequest);
  yield takeLatest(setUserAsAdmin, onSetUserAsAdmin);
  yield takeLatest(setUserModal, () => {});
  yield takeLatest(resetUserModal, () => {});
}
