import { getUserAgentInfo } from "@API/Utilities";
import axios, {
  AxiosRequestConfig,
  AxiosStatic,
  CreateAxiosDefaults,
} from "axios";

/**
 * Creates a new axios instance with the given configuration.
 * @param {AxiosRequestConfig["baseURL"]} baseURL The base URL.
 * @param {CreateAxiosDefaults["headers"]} customHeaders The custom headers.
 * @returns {ReturnType<AxiosStatic["create"]>} The new axios instance.
 */
const createAxiosInstance = (
  baseURL: AxiosRequestConfig["baseURL"],
  customHeaders: CreateAxiosDefaults["headers"]
): ReturnType<AxiosStatic["create"]> => {
  return axios.create({
    baseURL,
    headers: customHeaders,
  });
};

/**
 * Generates common request headers used for authentication.
 * @returns {CreateAxiosDefaults["headers"]} Common request headers.
 */
const commonRequestHeaders = (extraHeaders?: any): CreateAxiosDefaults["headers"] => ({
  ...extraHeaders,
  Authorization: `${localStorage.getItem("tokenType")} ${localStorage.getItem(
    "accessToken"
  )}`,
  "X-Blackpin-Device-ID": process.env.REACT_APP_CLIENT_ID,
  "X-Blackpin-Device-Name": btoa(getUserAgentInfo()),
});

/**
 * Creates an axios instance for making authenticated requests.
 * @returns {ReturnType<typeof createAxiosInstance>} Axios instance for authenticated requests.
 */
export const getAuthRequest = (): ReturnType<typeof createAxiosInstance> =>
  createAxiosInstance(process.env.REACT_APP_BASE_AUTH_URL, {
    "Content-Type": "application/json",
  });

export const postAxiosAdminRequest = (): ReturnType<
  typeof createAxiosInstance
> =>
  axios.create({
    baseURL: process.env.REACT_APP_BASE_API_ADMIN_URL,
    headers: {
      ...commonRequestHeaders(),
      "Content-Type": "application/json",
    },
  });

export const getAxiosAdminInvitationsRequest = (): ReturnType<
  typeof createAxiosInstance
> =>
  axios.create({
    baseURL: process.env.REACT_APP_BASE_API_ADMIN_URL,
    headers: {
      ...commonRequestHeaders(),
    },
  });

/**
 * Creates an axios instance for making requests related to policies.
 * @param {string} policyType The type of the policy (e.g., 'privacy', 'terms').
 * @returns {ReturnType<typeof createAxiosInstance>} Axios instance for policies.
 */
export const getAxiosPolicyRequest = (policyType: string): ReturnType<typeof createAxiosInstance> => {
    const baseUrl = `${process.env.REACT_APP_BASE_POLICIES_URL}/${policyType}`;
    return createAxiosInstance(baseUrl, commonRequestHeaders());
};

export const getAxiosPoliciesRequest = (): ReturnType<
    typeof createAxiosInstance
> =>
    axios.create({
        baseURL: `${process.env.REACT_APP_BASE_API_URL}/policies`,
        headers: commonRequestHeaders(),
    });
/**
 * Creates an axios instance for making requests related to organizations.
 * @returns {ReturnType<typeof createAxiosInstance>} Axios instance for organizations.
 */
export const getAxiosOrganizationRequest = (): ReturnType<
  typeof createAxiosInstance
> =>
  axios.create({
    baseURL: process.env.REACT_APP_BASE_ORGANIZATIONS_URL,
    headers: commonRequestHeaders(),
  });

export const getAxiosAdminOrganizationRequest = (): ReturnType<
  typeof createAxiosInstance
> =>
  axios.create({
    baseURL: process.env.REACT_APP_BASE_ADMIN_ORGANIZATIONS_URL,
    headers: commonRequestHeaders(),
  });

export const getAxiosBulkImportTeamRequest = (): ReturnType<
  typeof createAxiosInstance
> => createAxiosInstance(
  process.env.REACT_APP_BASE_ADMIN_ORGANIZATIONS_URL,
  commonRequestHeaders({
    'Content-Type': 'multipart/form-data',
  })
);


/**
 * Creates an axios instance for making requests related to organization users.
 * @returns {ReturnType<typeof createAxiosInstance>} Axios instance for organization users.
 */
export const getAxiosOrganizationUsersRequest = (): ReturnType<
  typeof createAxiosInstance
> =>
  createAxiosInstance(
    process.env.REACT_APP_BASE_ORGANIZATIONS_URL,
    commonRequestHeaders()
  );

/**
 * Creates an axios instance for making requests related to organization users.
 * @returns {ReturnType<typeof createAxiosInstance>} Axios instance for organization users.
 */
export const patchAxiosOrganizationUserRequest = (): ReturnType<
  typeof createAxiosInstance
> =>
  createAxiosInstance(process.env.REACT_APP_BASE_ORGANIZATIONS_URL, {
    "Content-Type": "application/json",
    ...commonRequestHeaders(),
  });

/**
 * Creates an axios instance for making requests related to the application's users.
 * @returns {ReturnType<typeof createAxiosInstance>} Axios instance for application users.
 */
export const getAppUserRequest = (): ReturnType<typeof createAxiosInstance> =>
  createAxiosInstance(
    process.env.REACT_APP_BASE_API_URL,
    commonRequestHeaders()
  );

/**
 * Creates an axios instance for making requests to create organizations.
 * @returns {ReturnType<typeof createAxiosInstance>} Axios instance for creating an organization.
 */
export const postAxiosCreateOrganizationRequest = (): ReturnType<
  typeof createAxiosInstance
> =>
  createAxiosInstance(process.env.REACT_APP_BASE_ADMIN_ORGANIZATIONS_URL, {
    "Content-Type": "application/json",
    ...commonRequestHeaders(),
  });

export const getAxiosMatrixServersRequest = (): ReturnType<
  typeof createAxiosInstance
> =>
  createAxiosInstance(
    process.env.REACT_APP_BASE_MATRIX_URL,
    commonRequestHeaders()
  );


/**
 * Creates an axios instance for making PUT requests related to organizations.
 * @returns {ReturnType<typeof createAxiosInstance>} Axios instance for updating an organization.
 */
export const putAxiosOrganizationRequest = (): ReturnType<typeof createAxiosInstance> =>
    createAxiosInstance(process.env.REACT_APP_BASE_ORGANIZATIONS_URL, {
        "Content-Type": "application/json",
        ...commonRequestHeaders(),
    });
