import { createAction } from "@reduxjs/toolkit";
import {
  IOrganizationUser,
  OrganizationId,
  OrganizationUserId,
  PatchOrganizationUserBody,
} from "@Store/Organizations/types";
import { IUserModalType } from "@Store/Users/types";

export const usersListRequest = createAction<{
  orgId: OrganizationId;
  active?: boolean | undefined;
}>("USERS_LIST/REQUEST");
export const usersListSuccess =
  createAction<IOrganizationUser[]>("USERS_LIST/SUCCESS");
export const usersListFail = createAction<Error>("USERS_LIST/FAIL");

export const updateUserRequest = createAction<
  {
    orgId: OrganizationId;
    userId: OrganizationUserId;
  } & PatchOrganizationUserBody
>("UPDATE_USER/REQUEST");
export const updateUserSuccess = createAction<IOrganizationUser>(
  "UPDATE_USER/SUCCESS"
);
export const updateUserFail = createAction<Error>("UPDATE_USER/FAIL");

export const createUserRequest = createAction<IOrganizationUser>(
  "CREATE_USER/REQUEST"
);
export const createUserSuccess = createAction<IOrganizationUser>(
  "CREATE_USER/SUCCESS"
);
export const createUserFail = createAction<Error>("CREATE_USER/FAIL");

export const deleteUserRequest = createAction<IOrganizationUser>(
  "DELETE_USER/REQUEST"
);
export const deleteUserSuccess = createAction<number>("DELETE_USER/SUCCESS");
export const deleteUserFail = createAction<Error>("DELETE_USER/FAIL");

export const setUserModal = createAction<IOrganizationUser>("SET_MODAL_USER");
export const setUserModalType = createAction<IUserModalType>(
  "SET_MODAL_USER_TYPE"
);
export const resetUserModal = createAction<void>("MODAL_USER_RESET");

export const setUserAsAdmin =
  createAction<IOrganizationUser>("SET_USER_AS_ADMIN");
