import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@hooks";
import { RootState } from "@Store";
import { selectAppUserData } from "@Store/AppUser/selectors";
import { selectIsAuthenticated, selectLanguage } from "@Store/App/selectors";
import { appUserDataRequest } from "@Store/AppUser/actions";
import { getOrganizationByIdRequest } from "@Store/Organizations/actions";
import LoginPage from "@Pages/Login";
import SingleOrganizationView from "@Pages/OrganizationView";
import UsersManagementPage from "@Pages/AdminPages/UsersManagement";
import InvitationsPage from "@Pages/AdminPages/Invitations";
import CompanyLegalPage from "@Pages/CompanyLegal";
import CorporatePage from "@Pages/Corporate";
import SelectOrganizationPage from "@Pages/OrganizationPageSelectMain";
import Header from "@Components/Header";
import MiniDrawer from "@Components/SideBar";
import FooterPoweredByBlackpin from "@Components/Footer Powered By Blackpin/footer-powered-by-blackpin";
import theme from "@src/theme";
import SnackbarComponent from "@Components/SnackBarComponent";
import TeamsManagementPage from "@Pages/AdminPages/TeamsManagement";

function MainRouter() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isOrgSelected, setIsOrgSelected] = useState<string | null>(
    sessionStorage.getItem("selectedOrganization")
  );

  const { isAuthenticated, selectedLanguage, appUser } = useAppSelector(
    (state: RootState) => ({
      isAuthenticated: selectIsAuthenticated(state),
      selectedLanguage: selectLanguage(state),
      appUser: state.appUser,
    })
  );

  useEffect(() => {
    if (!isAuthenticated) {
      setIsOrgSelected(null);
      return;
    }
    dispatch(appUserDataRequest());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && appUser.general.authChecked) {
      history.push("select-organization");
    }
  }, [isAuthenticated && appUser.general.authChecked]);

  useEffect(() => {
    const selectedOrg = sessionStorage.getItem("selectedOrganization");
    if (selectedOrg) {
      dispatch(getOrganizationByIdRequest(selectedOrg));
    }
  }, [dispatch, isOrgSelected]);

  const handleOrgSelect = useCallback(
    (orgId: React.SetStateAction<string | null>) => {
      if (typeof orgId === "string") {
        sessionStorage.setItem("selectedOrganization", orgId);
      }
      setIsOrgSelected(orgId);
    },
    []
  );

  if (!appUser.general.authChecked && !localStorage.getItem("accessToken")) {
    return (
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Redirect to="/login" />
      </Switch>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        background: "#000 !important",
        backgroundColor: "black",
      }}
    >
      <SnackbarComponent />
      {isOrgSelected && <MemoizedHeader onOrgSelect={handleOrgSelect} />}
      <div
        style={{
          width: "100%",
          display: "flex",
          height: "95%",
          backgroundColor: theme.palette.background.default,
        }}
      >
        {isOrgSelected && <MemoizedMiniDrawer />}
        <div className="switch-container" style={{ flex: 1 }}>
          <Switch>
            <Route
              path="/company-organizations/:id"
              component={SingleOrganizationView}
            />
            <Route path="/corporate" component={CorporatePage} />
            <Route path="/company-legal" component={CompanyLegalPage} />
            <Route path="/user-management" component={UsersManagementPage} />
            <Route path="/invitations" component={InvitationsPage} />
            <Route path="/teams" component={TeamsManagementPage} />

            <Route path="/select-organization">
              {!isOrgSelected ? (
                <SelectOrganizationPage onOrgSelect={handleOrgSelect} />
              ) : (
                <Redirect to="/corporate" />
              )}
            </Route>

            <Redirect from="*" to="/select-organization" />
          </Switch>
          {isOrgSelected && <MemoizedFooterPoweredByBlackpin />}
        </div>
      </div>
    </div>
  );
}

const MemoizedHeader = React.memo(Header);
const MemoizedMiniDrawer = React.memo(MiniDrawer);
const MemoizedFooterPoweredByBlackpin = React.memo(FooterPoweredByBlackpin);

export default MainRouter;
