import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {
  activateTeamFail,
  activateTeamSuccess,
  bulkImportTeamFail,
  bulkImportTeamRequest,
  bulkImportTeamSuccess,
  createTeamFail,
  createTeamRequest,
  createTeamSuccess,
  deactivateTeamFail,
  deactivateTeamSuccess,
  resetSingleTeam,
  singleTeamFail,
  singleTeamRequest,
  singleTeamSuccess,
  teamsListFail,
  teamsListRequest,
  teamsListSuccess,
  updateTeamFail,
  updateTeamRequest,
  updateTeamSuccess,
} from "./actions";
import {ITeam} from "@API/Teams/types";

interface ITeamsState {
  list: ITeam[];
  isLoading: boolean;
  success: boolean;
  fail: boolean;
  singleTeam: any;
}

const INITIAL_STATE: ITeamsState = {
  list: [],
  isLoading: false,
  success: false,
  fail: false,
  singleTeam: null,
};

const teamsReducer = createReducer(INITIAL_STATE, {
  [teamsListRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [teamsListSuccess.type]: (state, {payload}: PayloadAction<ITeam[]>) => ({
    ...state,
    list: payload,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [teamsListFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),
  [createTeamRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [createTeamSuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [createTeamFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),

  [updateTeamRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [updateTeamSuccess.type]: (state, {payload}: PayloadAction<ITeam>) => ({
    ...state,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [updateTeamFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),

  [singleTeamRequest.type]: (state) => ({
    ...state,
    singleTeam: null,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [singleTeamSuccess.type]: (state, {payload}: any) => {
    return {
      ...state,
      singleTeam: payload,
      isLoading: false,
      success: true,
      fail: false,
    };
  },
  [singleTeamFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),
  [resetSingleTeam.type]: (state) => ({
    ...state,
    singleTeam: null,
  }),
  [activateTeamSuccess.type]: (state, {payload}: any) => ({
    ...state,
    list: state.list.map((team) =>
      team.code === payload.teamCode ? {...team, chatEnabled: true} : team
    ),
  }),
  [deactivateTeamSuccess.type]: (state, {payload}: any) => ({
    ...state,
    list: state.list.map((team) =>
      team.code === payload.teamCode ? {...team, chatEnabled: false} : team
    ),
  }),

  [activateTeamFail.type]: (state) => ({
    ...state,
    fail: true,
  }),
  [deactivateTeamFail.type]: (state) => ({
    ...state,
    fail: true,
  }),
  [bulkImportTeamRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    uploadSuccess: false,
    uploadError: null,
  }),
  [bulkImportTeamSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    uploadSuccess: true,
    uploadError: null,
    uploadedData: action.payload,
  }),
  [bulkImportTeamFail.type]: (state, action) => ({
    ...state,
    isLoading: false,
    uploadSuccess: false,
    uploadError: action.payload,
  }),
});

export default teamsReducer;
