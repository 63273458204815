import React, { useState, MouseEvent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CustomToolbar from "@Pages/ClientsOrganisations/CustomToolbarTest";
import { useTranslation } from "react-i18next";

export interface Column {
  field: string;
  headerName: string;
  sortable?: boolean;
  sortBy?: string;
  hidden?: boolean;
}

export interface Data {
  id: number;

  [key: string]: React.ReactNode;
}

interface MenuItemType {
  name: string;
  onClick: (row: Data) => void;
}

interface CustomTableProps {
  columns: Column[];
  data: Data[];
  onRowClick?: (row: Data) => void;
  rowsPerPage?: number;
  menuItems?: MenuItemType[];
  selectable?: boolean; // New prop for controlling selection
}

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  data,
  onRowClick,
  rowsPerPage = 10,
  menuItems = [],
  selectable = true, // Default to true for backwards compatibility
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = useState<Data | null>(null);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(rowsPerPage);
  const { t } = useTranslation();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleMenuClick = (event: MouseEvent<HTMLElement>, row: Data) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleDelete = () => {
    console.log("Delete selected rows");
  };

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageState(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = data.sort((a, b) => {
    if (orderBy) {
      const column = columns.find((col) => col.field === orderBy);
      if (column?.sortable !== false) {
        const sortField = column?.sortBy || orderBy;
        const aValue = a[sortField] as string;
        const bValue = b[sortField] as string;

        if (aValue < bValue) {
          return order === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      }
    }
    return 0;
  });

  const paginatedData = sortedData.slice(
    page * rowsPerPageState,
    page * rowsPerPageState + rowsPerPageState
  );

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "4px", overflow: "hidden" }}
    >
      {selectable && (
        <CustomToolbar numSelected={selected.length} onDelete={handleDelete} />
      )}
      <Table
        sx={{
          borderCollapse: "separate",
          borderSpacing: "0",
          "& .MuiTableCell-root": {
            borderColor: "#363636",
          },
          "& .MuiTableCell-head": {
            borderColor: "#363636",
          },
          "& .MuiTableCell-body": {
            borderColor: "#363636",
          },
          "& .MuiTableRow-root:hover": {
            backgroundColor: "#111111",
            cursor: "pointer",
          },
        }}
      >
        <TableHead>
          <TableRow>
            {selectable && (
              <TableCell>
                <Checkbox
                  className="checkbox-custom"
                  sx={{
                    borderRadius: "100%",
                    "& .MuiSvgIcon-root": {
                      borderRadius: "50%",
                    },
                  }}
                  indeterminate={
                    selected.length > 0 && selected.length < data.length
                  }
                  checked={data.length > 0 && selected.length === data.length}
                  onChange={handleSelectAllClick}
                  icon={<PanoramaFishEyeIcon />}
                  checkedIcon={<CheckCircleOutlineIcon />}
                  indeterminateIcon={<RemoveCircleOutlineIcon />}
                />
              </TableCell>
            )}
            {columns
              .filter((column) => !column.hidden)
              .map((column) => (
                <TableCell
                  key={column.field}
                  sx={{ fontWeight: "700", color: "#9E9E9E" }}
                >
                  {column.sortable !== false ? (
                    <TableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? order : "asc"}
                      onClick={() => handleSort(column.field)}
                    >
                      {column.headerName}
                    </TableSortLabel>
                  ) : (
                    column.headerName
                  )}
                </TableCell>
              ))}

            {menuItems.length > 0 && (
              <TableCell
                sx={{ width: "50px", fontWeight: "700", color: "#9E9E9E" }}
              ></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((row) => {
            const isItemSelected = isSelected(row.id);
            return (
              <TableRow
                key={row.id}
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                selected={isItemSelected}
                onClick={() => (onRowClick ? onRowClick(row) : null)}
              >
                {selectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      sx={{ borderRadius: "50%" }}
                      checked={isItemSelected}
                      onChange={(event) => handleClick(event, row.id)}
                      icon={<PanoramaFishEyeIcon />}
                      checkedIcon={<CheckCircleOutlineIcon />}
                    />
                  </TableCell>
                )}
                {columns
                  .filter((column) => !column.hidden)
                  .map((column) => (
                    <TableCell key={column.field}>
                      {row[column.field]}
                    </TableCell>
                  ))}
                {menuItems.length > 0 && (
                  <TableCell>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleMenuClick(event, row)}
                      sx={{ color: "white" }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      sx={{ backgroundColor: "transparent" }}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {menuItems.map((menuItem) => (
                        <MenuItem
                          key={menuItem.name}
                          sx={{ backgroundColor: "#4C4C4C" }}
                          onClick={() => {
                            menuItem.onClick(currentRow!);
                            handleMenuClose();
                          }}
                        >
                          {menuItem.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPageState}
        labelRowsPerPage={t("table.rowsPerPageText")}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default CustomTable;
