import React, { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "@hooks";
import { RootState } from "@Store";
import {
  createInviteUserRequest,
  resetInviteUserModal,
} from "@Store/Invites/actions";
import { selectCurrentOrganization } from "@Store/Organizations/selectors";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useTranslation } from "react-i18next";
import { date, object, string } from "yup";
import { Form, Formik, Field } from "formik";
import i18n from "@i18n";
import {
  formatUserInvitationData,
  selectInviteUserModalValues,
} from "@Pages/AdminPages/Invitations/selectors";
import FileUpload from "@Components/FileUpload/file-upload";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import "./style.scss";

const schema = object().shape({
  bpUserName: string()
    .email(i18n.t("inviteUser.modal.validation.emailAddress.format"))
    .required(i18n.t("inviteUser.modal.validation.emailAddress.required")),
  validUntil: date()
    .nullable()
    .required(i18n.t("inviteUser.modal.validation.validUntil.required"))
    .min(new Date(), i18n.t("inviteUser.modal.validation.validUntil.minDate")),
});

const ModalInviteUser: FunctionComponent<any> = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { currentOrganization } = useAppSelector((state: RootState) => ({
    currentOrganization: selectCurrentOrganization(state),
  }));

  const onSubmit = (data: any) => {
    const formattedData: any = formatUserInvitationData(data);

    dispatch(
      createInviteUserRequest({
        orgId: currentOrganization.orgId,
        bpUserName: formattedData.bpUserName,
        validUntil: formattedData.validUntil.toISOString(),
      })
    );

    onClose();
  };

  const initialValuesLocal: any = {
    bpUserName: "",
    validUntil: new Date(),
  };

  return (
    <Dialog open={visible} onClose={onClose} style={{ marginBottom: "200px" }}>
      <DialogTitle sx={{ width: "500px" }}>
        {t("inviteUser.modal.title")} {currentOrganization.orgName}
      </DialogTitle>
      <Formik
        initialValues={initialValuesLocal}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, setFieldValue, isValid, dirty }) => (
          <Form className="invite-user-modal-content">
            <DialogContent>
              <Typography>{t("inviteUser.modal.emailAddress")}</Typography>
              <Field
                name="bpUserName"
                as={TextField}
                fullWidth
                margin="normal"
                error={touched.bpUserName && Boolean(errors.bpUserName)}
                helperText={touched.bpUserName && errors.bpUserName}
              />
              <Typography sx={{ mt: 2 }}>
                {t("inviteUser.modal.validUntil")}
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{ width: "100%" }}
                  value={dayjs(initialValuesLocal.validUntil)}
                  onChange={(value) => setFieldValue("validUntil", value)}
                  minDate={dayjs(new Date())}
                />
              </LocalizationProvider>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                {t("buttons.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={!isValid || !dirty}
              >
                {t("inviteUser.buttons.inviteUser")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ModalInviteUser;
