import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks";
import { RootState } from "@Store";
import {
  createTeamRequest,
  singleTeamRequest,
  updateTeamRequest,
} from "@Store/Team/actions";
import { SelectChangeEvent } from "@mui/material/Select";
import { selectCurrentOrganization } from "@Store/Organizations/selectors";
import { usersListRequest } from "@Store/Users/actions";
import MembersSection from "@Components/CreateEditTeamsModal/MembersSection";
import SelectUsersModal from "@Components/CreateEditTeamsModal/SelectUsersModal";
import { useTranslation } from "react-i18next";

interface CreateEditTeamModalProps {
  open: boolean;
  handleClose: () => void;
  teamData?: any;
}

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .max(25, "Name cannot be longer than 25 characters"),
  description: Yup.string().max(
    85,
    "Description cannot be longer than 85 characters"
  ),
  ownerId: Yup.string().required("Owner is required"),
  memberUserIdList: Yup.array().of(Yup.string()),
});

interface ICreateTeamPayload {
  code: string;
  name: string;
  description: string;
  parentTeamId?: number | null | undefined;
  defaultChatOwnerUserId: string;
  memberUserIdList: string[];
}

const CreateEditTeamModal: React.FC<CreateEditTeamModalProps> = ({
  open,
  handleClose,
  teamData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSelectUsersModalOpen, setIsSelectUsersModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [removedMemberList, setRemovedMemberList] = useState<string[]>([]);

  const handleOpenSelectUsersModal = () => setIsSelectUsersModalOpen(true);
  const handleCloseSelectUsersModal = () => setIsSelectUsersModalOpen(false);

  const { currentOrganization, users, singleTeamData } = useAppSelector(
    (state: RootState) => ({
      currentOrganization: selectCurrentOrganization(state),
      users: state.users.general.list,
      singleTeamData: state.teams.singleTeam,
    })
  );

  const handleCloseCreateModal = () => {
    formik.resetForm();
    setSelectedUsers([]);
    setRemovedMemberList([]);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      const orgId = currentOrganization?.orgId
        ? currentOrganization.orgId.toString()
        : sessionStorage.getItem("selectedOrganization") || "";

      dispatch(
        usersListRequest({
          orgId: orgId,
          active: true,
        })
      );

      if (teamData && teamData.code) {
        dispatch(
          singleTeamRequest({
            orgId: currentOrganization?.orgId,
            id: teamData.code,
          })
        );
      }
    }
  }, [open, dispatch, currentOrganization?.orgId, teamData]);

  useEffect(() => {
    if (singleTeamData && open) {
      const teamMembers = singleTeamData.members.map((member: any) =>
        users.find((user: any) => user.userId === member.userId)
      );
      setSelectedUsers(teamMembers.filter(Boolean));
    }
  }, [singleTeamData, open, users]);

  const handleRemoveMember = (userId: string) => {
    setRemovedMemberList((prevList) => [...prevList, userId]);
    const updatedUsers = selectedUsers.filter((user) => user.userId !== userId);
    setSelectedUsers(updatedUsers);
    formik.setFieldValue(
      "memberUserIdList",
      updatedUsers.map(({ userId }) => userId)
    );
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: singleTeamData?.name || "",
      description: singleTeamData?.description || "",
      ownerId: singleTeamData?.ownerUserId || "",
      parentTeamId: singleTeamData?.parentTeamId || 0,
      memberUserIdList:
        singleTeamData?.members.map((member: any) => member.userId) || [],
      code: singleTeamData?.code || "",
    },

    validationSchema,
    onSubmit: (values) => {
      const requestData = teamData
        ? {
            name: values.name,
            description: values.description,
            addedMemberList: values.memberUserIdList.filter(
              (userId: any) =>
                !singleTeamData?.members.some(
                  (m: { userId: any }) => m.userId === userId
                )
            ),
            defaultChatOwnerUserId: values.ownerId,
            removedMemberList: removedMemberList,
          }
        : {
            name: values.name,
            description: values.description,
            code: values.name.toLowerCase().replace(/ /g, "_"),
            defaultChatOwnerUserId: values.ownerId,
            parentTeamId: null,
            memberUserIdList: values.memberUserIdList,
          };

      if (teamData) {
        dispatch(
          updateTeamRequest({
            teamId: teamData.code,
            orgId: currentOrganization?.orgId,
            payload: requestData,
          })
        );
      } else {
        if (currentOrganization?.orgId) {
          dispatch(
            createTeamRequest({
              orgId: currentOrganization.orgId,
              payload: requestData,
            })
          );
        }
      }

      handleClose();
    },
  });

  const handleSaveSelectedUsers = (users: any[]) => {
    setSelectedUsers(users);
    formik.setFieldValue(
      "memberUserIdList",
      users.map((user) => user.userId)
    );
  };

  const handleOwnerChange = (event: SelectChangeEvent<string>) => {
    formik.setFieldValue("ownerId", event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCloseCreateModal();
        }
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        overflow: "auto",
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        },
      }}
    >
      <Box
        className="modal-box"
        sx={{
          bgcolor: "#232323",
          borderRadius: "8px",
          boxShadow: 24,
          height: "700px",
          width: "500px",
          mx: "auto",
          mt: "10px",
          color: "white",
          padding: "20px 30px",
        }}
      >
        <div
          className="modal-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #363636",
            padding: "5px 10px",
            marginBottom: "16px",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {teamData
              ? t("teamManagement.editTeamTitle")
              : t("teamManagement.addNewTeamTitle")}
          </Typography>
          <IconButton onClick={handleCloseCreateModal} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <Typography variant="body2">
            {t("teamManagement.nameLabel")}:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4C4C4C" },
              },
              mb: 2,
            }}
          />

          <Typography variant="body2">
            {t("teamManagement.descriptionLabel")}:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4C4C4C" },
              },
              mb: 2,
            }}
          />

          <Typography variant="body2" sx={{ mb: 1 }}>
            {t("teamManagement.teamChatOwnerLabel")}:
          </Typography>
          <Select
            value={formik.values.ownerId}
            onChange={handleOwnerChange}
            displayEmpty
            variant="outlined"
            style={{
              width: "100%",
              marginBottom: "25px",
              padding: "0",
              backgroundColor: "#2b2b2b",
              color: "white",
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 400,
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          >
            <MenuItem value="">
              {t("teamManagement.selectOwnerPlaceholder")}
            </MenuItem>
            {[...users]
              .sort((a, b) => a.givenName.localeCompare(b.givenName))
              .map((user) => (
                <MenuItem key={user.userId} value={user.userId}>
                  {user.givenName + " " + user.surName}
                </MenuItem>
              ))}
          </Select>

          <MembersSection
            handleOpenSelectUsersModal={handleOpenSelectUsersModal}
            users={selectedUsers}
            onRemoveMember={handleRemoveMember}
          />

          <SelectUsersModal
            open={isSelectUsersModalOpen}
            handleClose={handleCloseSelectUsersModal}
            users={users}
            onSave={handleSaveSelectedUsers}
            selectedUsers={selectedUsers}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!(formik.isValid && formik.dirty)}
            sx={{
              borderRadius: "4px",
            }}
          >
            {t("teamManagement.saveButton")}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateEditTeamModal;
